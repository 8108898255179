import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import { useForm } from "react-hook-form";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import DeleteModal from "../../components/Base/DeleteModal";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import {
  DELETE_TIERS_BENEFITS,
  GET_TIERS_BENEFITS,
  SAVE_TIERS_BENEFITS,
  SORT_TIERS_BENEFITS,
  UPDATE_TIERS_BENEFITS,
} from "../../request/config/Urls";
import {
  deleteApiData,
  fetchApiData,
  saveApiData,
  updateApiData,
  updateData,
} from "../../request/config/index";
import FormValidationError from "../../components/Base/FormValidationError";

interface TierBenefits {
  id: number;
  title: string;
  bronze: number | null;
  bronze_checked: number | null;
  silver: number | null;
  silver_checked: number | null;
  gold: number | null;
  gold_checked: number | null;
  diamond: number | null;
  diamond_checked: number | null;
  sort_id: number;
  created_at: string;
  updated_at: string;
}

const TierBenefits = () => {
  const [showInput, setShowInput] = useState({
    bronze: false,
    silver: false,
    gold: false,
    diamond: false,
  });

  const dispatch = useDispatch();

  const handleCheckboxClick = (checkboxId: any) => {
    setShowInput((prevShowInput: any) => ({
      ...prevShowInput,
      [checkboxId]: !prevShowInput[checkboxId],
    }));
  };

  const [tiersBenefits, setTierBenefits] = useState<TierBenefits[]>([]);
  const {
    handleSubmit,
    register,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteTierBenefitId, setDeleteTierBenefitId] = useState(0);

  const getTierList = async () => {
    const url = GET_TIERS_BENEFITS;
    try {
      const response = await fetchApiData({ url });
      Object.keys(response.data).forEach((item: any) => {
        setValue(item, response.data[item]);
      });
      setTierBenefits(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    getTierList();
  }, []);

  const onSubmit = async (data: any) => {
    try {
      data.bronze_checked = showInput.bronze ? 1 : 0;
      data.silver_checked = showInput.silver ? 1 : 0;
      data.gold_checked = showInput.gold ? 1 : 0;
      data.diamond_checked = showInput.diamond ? 1 : 0;


      if (data.id) {
        let res = await updateData({
          url: UPDATE_TIERS_BENEFITS + "/" + data.id,
          body: data,
        });
        if (res) {
          reset();
          getTierList();
          dispatch(alertSuccess("Benefit updated successfully"));
        }
        setShowInput({
          bronze: false,
          silver: false,
          gold: false,
          diamond: false,
        });
      } else {
        data["sort_id"] = tiersBenefits.length + 1;
        const response = await saveApiData({
          url: SAVE_TIERS_BENEFITS,
          body: data,
        });
        getTierList();
        dispatch(alertSuccess("Benefit added successfully"));
        reset();
        setShowInput({
          bronze: false,
          silver: false,
          gold: false,
          diamond: false,
        });
      }
    } catch (error: any) {
      console.error("API Error:", error.message);
      dispatch(alertFailure(error.message));
    }
  };

  function handleEdit(data: any) {
    if (typeof window !== "undefined") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
    Object.keys(data).forEach((item: any) => {
      setValue(item, data[item]);
    });

    const updatedShowInput: { [key: string]: boolean } = {};
    Object.keys(showInput).forEach((checkboxId) => {
      updatedShowInput[checkboxId] = Boolean(data[checkboxId]);

      handleCheckboxClick(checkboxId);
    });

    setShowInput(
      updatedShowInput as {
        bronze: boolean;
        silver: boolean;
        gold: boolean;
        diamond: boolean;
      }
    );
    setShowInput({
      bronze: data.bronze_checked === 1,
      silver: data.silver_checked === 1,
      gold: data.gold_checked === 1,
      diamond: data.diamond_checked === 1,
    });
  }

  async function handleDelete() {
    try {
      const url = `${DELETE_TIERS_BENEFITS}/${deleteTierBenefitId}`;
      const res = await deleteApiData({ url });
      if (res) {
        dispatch(alertSuccess("Deleted successfully"));
        getTierList();
      }
    } catch (err: any) {
      dispatch(alertFailure(`Error: ${err?.message || "Unable to delete"}`));
    }
    setDeleteModalOpen(false);
  }

  const updateSorting = async (updatedData: any) => {
    try {
      const response = await updateApiData({
        url: SORT_TIERS_BENEFITS,
        body: { tier_benefits: updatedData },
      });
      if (response.status === 200) {
        dispatch(alertSuccess("Updated Successfully"));
      } else {
        dispatch(alertFailure(response?.message));
      }
    } catch (error: any) {
      console.error(`${error?.message || "Error Updating"}`);
      dispatch(alertFailure(`${error?.message || "Error Updating"}`));
    }
  };

  const onDragEnd = (result: any) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }

    const items: TierBenefits[] = Array.from(tiersBenefits);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    const data = items.map((item, index) => ({
      id: item?.id,
      sort_id: index + 1,
    }));
    updateSorting(data);
    setTierBenefits(items);
  };

  return (
    <div>
      <Row>
        <Col xs={12}>
          <CardBody className="p-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="text" hidden name="id" ref={register()} />
              <div className="row">
                <div className="col-lg-12">
                  <div className="mb-3">
                    <label
                      htmlFor="basicpill-firstname-input"
                      className="form-label"
                    >
                      Enter Tier Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="basicpill-firstname-input"
                      placeholder="Enter Title"
                      name="title"
                      ref={register({
                        required: "This Field is Required",
                      })}
                      //   {...register("title", {
                      //     required: {
                      //       value: true,
                      //       message: "Tier Title is Required",
                      //     },
                      //   })}
                    />
                    <FormValidationError
                      errorMessage={errors?.title?.message}
                    />
                  </div>
                </div>
              </div>
              <Row>
                <Col md={5}>
                  <div>
                    <h5 className="font-size-14 mb-3">
                      <i className="mdi mdi-arrow-right text-primary me-1"></i>{" "}
                      Select Tier
                    </h5>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="bronze"
                        onClick={() => handleCheckboxClick("bronze")}
                        checked={showInput.bronze}
                      />
                      <label className="form-check-label" htmlFor="bronze">
                        DEGEN
                      </label>
                      {showInput.bronze && (
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Enter Amount"
                          name="bronze"
                          ref={register({
                            required: true,
                          })}
                          //   {...register("bronze")}
                        />
                      )}
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="silver"
                        onClick={() => handleCheckboxClick("silver")}
                        checked={showInput.silver}
                      />
                      <label className="form-check-label" htmlFor="silver">
                        APE
                      </label>
                      {showInput.silver && (
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Enter Amount"
                          name="silver"
                          ref={register({
                            required: true,
                          })}
                          //   {...register("silver")}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <Col md={5} className="m-1">
                  <div className="mt-4">
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="gold"
                        onClick={() => handleCheckboxClick("gold")}
                        checked={showInput.gold}
                      />
                      <label className="form-check-label" htmlFor="gold">
                        WHALE
                      </label>
                      {showInput.gold && (
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Enter Amount"
                          name="gold"
                          ref={register({
                            required: true,
                          })}
                          //   {...register("gold")}
                        />
                      )}
                    </div>
                    <div className="form-check mb-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="diamond"
                        onClick={() => handleCheckboxClick("diamond")}
                        checked={showInput.diamond}
                      />
                      <label className="form-check-label" htmlFor="diamond">
                        CHAD
                      </label>
                      {showInput.diamond && (
                        <input
                          type="text"
                          className="form-control mt-2"
                          placeholder="Enter Amount"
                          name="diamond"
                          ref={register({
                            required: true,
                          })}
                          //   {...register("diamond")}
                        />
                      )}
                    </div>
                  </div>
                </Col>
                <div>
                  <button type="submit" className="btn btn-info w-md">
                    Submit
                  </button>
                </div>
              </Row>
            </form>
          </CardBody>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Label className="form-label">Tier Benefits Table</Label>
          <Card>
            <CardBody>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Tier Benefits</th>
                      <th>DEGEN</th>
                      <th>APE</th>
                      <th>WHALE</th>
                      <th>CHAD</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(provided) => (
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {tiersBenefits?.map((item, index) => {
                            console.log(item);

                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <tr
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <th scope="row">{item.id}</th>
                                    <td>{item.title}</td>
                                    <td>
                                      {item?.bronze_checked
                                        ? item?.bronze || (
                                            <i className="mdi mdi-check text-success font-size-22"></i>
                                          )
                                        : "-"}
                                    </td>
                                    <td>
                                      {item?.silver_checked
                                        ? item?.silver || (
                                            <i className="mdi mdi-check text-success font-size-22"></i>
                                          )
                                        : "-"}
                                    </td>
                                    <td>
                                      {item?.gold_checked
                                        ? item?.gold || (
                                            <i className="mdi mdi-check text-success font-size-22"></i>
                                          )
                                        : "-"}
                                    </td>
                                    <td>
                                      {item?.diamond_checked
                                        ? item?.diamond || (
                                            <i className="mdi mdi-check text-success font-size-22"></i>
                                          )
                                        : "-"}
                                    </td>

                                    <td>
                                      <span className="d-flex">
                                        <button
                                          className="btn btn-info btn-sm me-2"
                                          onClick={() => handleEdit(item)}
                                        >
                                          Edit
                                        </button>
                                        <button
                                          className="btn btn-danger btn-sm btn-sm"
                                          onClick={() => {
                                            setDeleteModalOpen(true);
                                            setDeleteTierBenefitId(item?.id);
                                          }}
                                        >
                                          Delete
                                        </button>
                                      </span>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <DeleteModal
        title="Tier Benefit"
        deleteModalOpen={deleteModalOpen}
        setDeleteModalOpen={setDeleteModalOpen}
        onDeleteClick={handleDelete}
      />
    </div>
  );
};

export default TierBenefits;
